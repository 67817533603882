import './App.css';

import {Home,Calc,Docs,Page404} from './Pages/Pages';
import {Route,Routes,useLocation} from 'react-router-dom';

function App() {
  const location=useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Home/>}/>
      <Route path="/calc" element={<Calc/>}/>
      <Route path="/docs" element={<Docs/>}/>
      <Route path="*" element={<Page404/>}/>
      </Routes>
  );
}

export default App;
