import { useState } from "react";
import {NavLink} from 'react-router-dom';



function Head(){
    
    const [openLeftPane,setOpenLeftPane]=useState(true); // open-close menu
    const [leftMenuIcon,setLeftMenuIcon]=useState(false); // change icon
    
    const openLeftPaneFun=()=>{
      setOpenLeftPane(!openLeftPane);
      setLeftMenuIcon(!leftMenuIcon);}

    return(
    <><div className="logo">Calculon
    <button className="openbtn" onClick={openLeftPaneFun}  id="menubtn">
      <i class={`fa fa-fw ${leftMenuIcon ? "fa-times":"fa-bars"}`} aria-hidden="true"></i>
    </button>
    </div>
    <div id="menupanel" className={`sidepanel${openLeftPane ? "" : " open"}`}>
      <NavLink to='/' onClick={openLeftPaneFun} activeClassName="active"><i class="fa fa-home" aria-hidden="true"></i> Home</NavLink>
      <NavLink to='/calc' onClick={openLeftPaneFun} activeClassName="active"><i class="fa fa-calculator" aria-hidden="true"></i> Calc</NavLink>
      <NavLink to='/docs' onClick={openLeftPaneFun} activeClassName="active"><i class="fa fa-folder-open" aria-hidden="true"></i> Docs</NavLink>
      {/* <NavLink to='/contact' onClick={openLeftPaneFun} activeClassName="active"><i class="fa fa-envelope" aria-hidden="true"></i> Contact</NavLink> */}
    </div>
    </>
)
}

export default Head;
