import './Calc.css';
import {useRef,useState} from 'react';
var BigNumber = require('bignumber.js');

export default function Calc(){

    const inputBoxValue = useRef("");
    const outputBoxValue = useRef("")
    const inputBase = useRef(10)
    const outputBase = useRef(2)
    const[isWrongInput,setWrongInput] = useState(false);


    function convert()
    {
        if(inputBoxValue.current.value!=="")
        {
            let inputNumber=new BigNumber(inputBoxValue.current.value,Number(inputBase.current.value));
            let result=inputNumber.toString(Number(outputBase.current.value)).toUpperCase();
            if(result==="NAN")
            {
                setWrongInput(true);
            }
            else
            {
            outputBoxValue.current.value= result;
            setWrongInput(false);
            }
        }
        else
        {
            setWrongInput(true);
        }

    }

    function reset()
    {
        setWrongInput(false);
        inputBoxValue.current.value="";
        outputBoxValue.current.value="";
    }

    function swap()
    {
        let temp=inputBase.current.value;
        inputBase.current.value=outputBase.current.value;
        outputBase.current.value=temp;
    }

    function copyResult()
    {
      if(outputBoxValue.current.value!==""){
        navigator.clipboard.writeText(outputBoxValue.current.value);
        alert("Result Copied")}
    }

    return(<div className="calc">
    <div className="calc-group">
      <label htmlFor="input-box" >Enter Number</label>
      <input type="text" className={`${isWrongInput ? "wrongInput":""}`} ref={inputBoxValue} name="input-box" id="input-box" />
    </div>
    <div className="calc-group">
      <label htmlFor="input-base">From Base</label>
      <select name="input-base" id="input-base" ref={inputBase}>
        <option value={2}>Binary (Base 2)</option>
        <option value={8}>Octal (Base 8)</option>
        <option value={10} selected>Decimal (Base 10)</option>
        <option value={16}>Hexadecimal (Base 16)</option>
      </select>
    </div>
    <div className="calc-group">    
      <label htmlFor="output-base">To Base</label>
      <select name="output-base" id="output-base" ref={outputBase}>
        <option value={2} selected>Binary (Base 2)</option>
        <option value={8}>Octal (Base 8)</option>
        <option value={10}>Decimal (Base 10)</option>
        <option value={16}>Hexadecimal (Base 16)</option>
      </select>
    </div>
    <div className="calc-group">
      <button onClick={convert}><i className="fa fa-exchange" aria-hidden="true" /> Convert</button>
      <button onClick={reset}><i className="fa fa-refresh" aria-hidden="true" /> Reset</button>
      <button onClick={swap}><i className="fa fa-random" aria-hidden="true" /> Swap</button>
    </div>
    <div className="calc-group">
      <textarea name="output-box" id="output-box" cols={30} rows={3} readOnly defaultValue={""} ref={outputBoxValue} />
      <button onClick={copyResult}><i className="fa fa-clipboard" aria-hidden="true" /> Copy Result</button>
    </div>
  </div>)
}